import {useNavigate} from 'react-router-dom'
import ButtonPrimary from './ButtonPrimary'
import PropTypes from 'prop-types'

const Benefits = ({className = ''}) => {
	const navigate = useNavigate()
	return (
		<section
			className={`self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-12 box-border max-w-full shrink-0 text-left text-63xl text-gray-600 font-plus-jakarta-sans mq450:pb-5 mq450:box-border mq1150:pb-[31px] mq1150:box-border ${className}`}>
			<div className="h-[78rem] lg:h-[800px] flex-1 [background:linear-gradient(180deg,_#f8f9fe,_#f8f8fb)] overflow-hidden flex flex-col lg:flex-row items-start justify-start py-[94.5px] pr-0 pl-[2rem] lg:pl-[140px] md:pl-[110px] box-border gap-[107px] max-w-full z-[0] mq450:gap-[27px] mq450:pl-5 mq450:box-border mq800:gap-[53px] mq800:pl-[70px] mq800:pt-[26px] mq800:pb-[26px] mq800:box-border mq1150:pt-10 mq1150:pb-10 mq1150:box-border mq1350:pt-[61px] mq1350:pb-[61px] mq1350:box-border">
				<div className="w-full lg:w-[568px] flex flex-col items-start justify-start gap-[24px]  shrink-0 mq1150:hidden mq1150:max-w-full">
					<h1 className="m-0 text-[3em] lg:text-[3em] self-stretch relative text-inherit tracking-[-1px] leading-[3.25rem] lg:leading-[3.5rem] font-medium font-inherit text-base-black mq450:text-6xl mq450:leading-[36px] mq800:text-22xl mq800:leading-[54px]">
						<span>{`Increase `}</span>
						<span className="text-transparent !bg-clip-text [background:linear-gradient(98.81deg,_#825bf8,_#5f44f6_44.79%,_#5caff9_96.35%)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">Innovation Performance</span>
						<span> with </span>
						<span className="text-transparent !bg-clip-text [background:linear-gradient(98.81deg,_#825bf8,_#5f44f6_44.79%,_#5caff9_96.35%)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">Derisking Intelligence.</span>
					</h1>
					<div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-4 box-border max-w-full text-lg">
						<div className="flex-1 relative leading-[150%] font-medium inline-block max-w-full text-[#475467]">{`Implement actionable innovation metrics. Measure & visualize your impact, derisk with smart experiments, make data-driven portfolio decisions, and save millions. Invest like a venture capitalist.`}</div>
					</div>
					<div className="flex flex-row items-start justify-start pt-0 pb-8 pr-5 pl-0">
						<ButtonPrimary className="text-white bg-[#6145F7] hover:!bg-[#422db6] rounded-3xl" onClick={() => navigate('/signup')} />
					</div>
					<div className="flex flex-col items-start justify-start py-0 pr-5 pl-0 gap-[24px] text-sm">
						<div className="h-10 flex flex-row items-start justify-start">
							<div className="relative inline-block">
								<iframe
									src="https://embed-v2.testimonial.to/badge/derisky-ai?starColor=825bf8&fontColor=000000&fontFamily=Plus+Jakarta+Sans&reviewTerm=review&fontSize=16&reviewTermPlural=reviews&alignment=left&customTemplate=%7Baverage%7D+stars+from+8+%7BreviewTerm%7D"
									className="border-0 w-full h-auto pointer-events-none"
									scrolling="no"
								></iframe>
								<a
									href="http://testimonial.to/derisky/all"
									target="_blank"
									className="absolute top-0 left-0 w-full h-full z-10 block"
								></a>
							</div>









                                </div>
                            </div>
                        </div>
                        {/* <div className="h-auto lg:h-[624px] w-auto lg:w-[624px] relative rounded-[50%] [background:linear-gradient(155.83deg,_rgba(69,_191,_247,_0.1)_17.41%,_rgba(255,_255,_255,_0))] hidden max-w-full" />
                        <div className="h-auto lg:h-[561.6px] w-[561.6px] relative rounded-[50%] [background:linear-gradient(155.83deg,_rgba(69,_158,_247,_0.05)_17.41%,_rgba(255,_255,_255,_0))] hidden max-w-full" /> */}
				<div className="mt-[2.4px] lg:mt-[-202.4px] h-auto lg:h-[1798.5px] flex-1 relative w-full lg:max-w-[calc(100%_-_675px)] text-21xl text-gray-800 mq1150:hidden mq1150:max-w-full">
					<img className="relative lg:absolute top-[1rem] h-auto lg:h-[80rem] bottom-[0px] left-[0px] max-h-full w-[964.9px]" alt="" src="/Union3.svg" />
					<div className="relative lg:absolute top-[-25.9rem] lg:top-[450.9px] left-[30px] lg:left-[90px] w-[311px] flex flex-col items-start justify-start gap-[2rem] z-[1]">
						<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
							<div className="self-stretch relative text-[2.5rem] tracking-[-1px] leading-[130%] capitalize font-semibold mq450:text-5xl mq450:leading-[31px] mq800:text-13xl mq800:leading-[42px]">5X</div>
							<div className="self-stretch relative text-[1.02rem]  leading-[34px] font-semibold text-gray-600">Faster Time-to-Market</div>
						</div>
						<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
							<div className="self-stretch relative text-[2.5rem] tracking-[-1px] leading-[130%] capitalize font-semibold mq450:text-5xl mq450:leading-[31px] mq800:text-13xl mq800:leading-[42px]">15X</div>
							<div className="self-stretch relativetext-[1.02rem] leading-[34px] font-semibold text-gray-600">ROI Over 3 Years</div>
						</div>
						<div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
							<div className="self-stretch relative text-[2.5rem] tracking-[-1px] leading-[130%] capitalize font-semibold mq450:text-5xl mq450:leading-[31px] mq800:text-13xl mq800:leading-[42px]">80%</div>
							<div className="self-stretch relativetext-[1.02rem] leading-[34px] font-semibold text-gray-600">Immediate Cost Savings</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

Benefits.propTypes = {
	className: PropTypes.string
}

export default Benefits
