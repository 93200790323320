import { useNavigate } from 'react-router-dom'
import {Icon} from "../../lib";

export function SubNavbar({ active, id }) {
	const navigate = useNavigate()
	return (
		<>
			<section
				className="rounded-sm text-brand-400 flex flex-wrap p-4 gap-3 shadow-md bg-brand-400
		">

				<nav
					className="flex items-center cursor-pointer relative"
					style={{ margin: '0rem 0.5rem' }}
					onClick={() => {
						navigate(`/singleproject/${id}`)

					}}>
					<Icon image='square' color={active === 'dashboard' ? 'derisky-rose' : 'white'} className="h-[1rem] w-[1rem] m-1" alt='' />

					<p className={active === 'dashboard' ? 'text-derisky-rose text-[1rem] leading[1.25rem]' : 'text-white text-[1rem] leading[1.25rem]'}>Dashboard</p>
					<div className={active === 'dashboard' ? 'border-t-2 shadow-[0px -3px 0px 0px ] w-[100%] border-derisky-rose absolute bottom-[-1.125rem] z-[3] left-0 bottom-[-1.125rem]]' : ''}></div>
				</nav>
				<nav
					className="flex items-center cursor-pointer relative"
					style={{ margin: '0rem 0.5rem' }}
					onClick={() => {
						navigate(`/business-model/${id}`)

					}}>
					<Icon image='grid' color={active === 'business-model' ? 'derisky-rose' : 'white'} className="h-[1rem] w-[1rem] m-1" alt='' />
					<p className={active === 'business-model' ? 'text-derisky-rose text-[1rem] leading[1.25rem]' : 'text-white text-[1rem] leading[1.25rem]'}>Business Models</p>
					<div className={active === 'business-model' ? 'border-t-2 shadow-[0px -3px 0px 0px ] w-[100%] border-derisky-rose absolute bottom-[-1.125rem] z-[3] left-0 bottom-[-1.125rem]]' : ''}></div>
				</nav>
				<nav
					className="flex items-center cursor-pointer relative"
					style={{ margin: '0rem 0.5rem' }}
					onClick={() => {
						navigate(`/cases/${id}`)
					}}>
					<Icon image='briefcase' color={active === 'case' ? 'derisky-rose' : 'white'} className="h-[1rem] w-[1rem] m-1" alt='' />

					<p className={active === 'case' ? 'text-derisky-rose text-[1rem] leading[1.25rem]' : 'text-white text-[1rem] leading[1.25rem]'}>Case</p>
					<div className={active === 'case' ? 'border-t-2 shadow-[0px -3px 0px 0px ]  w-[100%] border-derisky-rose absolute bottom-[-1.125rem] z-[3] left-0 bottom-[-1.125rem]]' : ''}></div>
				</nav>
				<nav
					className="flex items-center cursor-pointer   relative"
					style={{ margin: '0rem 0.5rem' }}
					onClick={() => {
						navigate(`/hypothesis/${id}`)
					}}>
					<Icon image='align-justify' color={active === 'hypotheses' ? 'derisky-rose' : 'white'} className="h-[1rem] w-[1rem] m-1" alt='' />

					<p className={active === 'hypotheses' ? 'text-derisky-rose text-[1rem] leading[1.25rem]' : 'text-white text-[1rem] leading[1.25rem]'}>Hypothesize</p>
					<div className={active === 'hypotheses' ? 'border-t-2 shadow-[0px -3px 0px 0px ]  w-[100%] border-derisky-rose absolute  left-0 bottom-[-1.125rem] bottom-[-1.125rem] z-[3]' : ''}></div>
				</nav>
				<nav
					className="flex items-center  cursor-pointer relative "
					style={{ margin: '0rem 0.5rem' }}
					onClick={() => {

						navigate(`/draganddrop/${id}`)
					}}>
					<Icon image='wind' color={active === 'experiments' ? 'derisky-rose' : 'white'} className="h-[1rem] w-[1rem] m-1" alt='' />

					<p className={active === 'experiments' ? 'text-derisky-rose text-[1rem] leading[1.25rem]' : 'text-white text-[1rem] leading[1.25rem]'}>Experiments</p>
					<div className={active === 'experiments' ? 'border-t-2 shadow-[0px -3px 0px 0px ]  w-[100%] border-derisky-rose absolute  left-0 bottom-[-1.125rem] z-[3]' : ''}></div>
				</nav>
				<nav
					className="flex items-center  cursor-pointer relative"
					style={{ margin: '0rem 0.5rem' }}
					onClick={() => {
						navigate(`/insight/${id}`)
					}}>
					<Icon image='zap' color={active === 'learnings' ? 'derisky-rose' : 'white'} className="h-[1rem] w-[1rem] m-1" alt='' />
					<p className={active === 'learnings' ? 'text-derisky-rose text-[1rem] leading[1.25rem]' : 'text-white text-[1rem] leading[1.25rem]'}>Insights</p>
					<div className={active === 'learnings' ? 'border-t-2 shadow-[0px -3px 0px 0px ] w-[100%] border-derisky-rose absolute bottom-[-18px] left-0 z-[3]' : ''}></div>
				</nav>
				<nav
					className="flex items-center  cursor-pointer relative"
					style={{ margin: '0rem 0.5rem' }}
					onClick={() => {
						navigate(`/documents/${id}`)
					}}>
					<Icon image='file-text' color={active === 'documents' ? 'derisky-rose' : 'white'} className="h-[1rem] w-[1rem] m-1" alt='' />
					<p className={active === 'documents' ? 'text-derisky-rose text-[1rem] leading[1.25rem]' : 'text-white text-[1rem] leading[1.25rem]'}>Documents</p>
					<div className={active === 'documents' ? 'border-t-2 shadow-[0px -3px 0px 0px ] w-[100%] border-derisky-rose absolute bottom-[-18px] left-0 z-[3]' : ''}></div>
				</nav>
				<nav
					className="flex items-center  cursor-pointer relative"
					style={{ margin: '0rem 0.5rem' }}
					onClick={() => {
						navigate(`/integration/${id}`)
					}}>
					<Icon image='zap' color={active === 'integration' ? 'derisky-rose' : 'white'} className="h-[1rem] w-[1rem] m-1" alt='' />
					<p className={active === 'integration' ? 'text-derisky-rose text-[1rem] leading[1.25rem]' : 'text-white text-[1rem] leading[1.25rem]'}>Integration</p>
					<div className={active === 'integration' ? 'border-t-2 shadow-[0px -3px 0px 0px ] w-[100%] border-derisky-rose absolute bottom-[-18px] left-0 z-[3]' : ''}></div>
				</nav>

			</section>
			<div className="border-t-2 shadow-[0px -3px 0px 0px ] border-[#CFD4DA]   "></div>
		</>
	)
}
