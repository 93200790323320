import {Icon, Row} from "../lib";
import React from "react";
import ReactGA from "react-ga4";
import {tagManager} from "../../app/googleTagManager";

export function SocialRow() {

    function tracklinks(platform) {
        // Send a custom event
        ReactGA.event({
            category: "social link click",
            action: platform,
            value: 1, // optional, must be a number
        });
        tagManager.track('social link click', {
            category: "social link click",
            action: platform
        });

    }

    return (
        <div className="margin-auto w-full  grid grid-cols-2 md:grid-cols-2 gap-5">
            <a
                href="https://linkedin.com/in/laurenslang"
                onClick={() => tracklinks('linkedin')}
                target="_blank"
                className="col-span-1 justify-self-center"
            >
                <Icon
                    className="opacity-50"
                    size="40"
                    image="linkedin"
                />
            </a>

            <a
                href="http://t.me/deriskyai"
                target="_blank"

                onClick={() => tracklinks('telegram')}
                className="col-span-1 justify-self-center"
            >
                <Icon
                    size="40"
                    className="opacity-50"
                    image="send"
                />
            </a>

        </div>);
}