import PropTypes from 'prop-types'
import Style from '../../commit/components/hero/hero.module.scss'
// /Users/mac/Downloads/MetrixDev/client-react-web-main/src/views/commit/components/hero/hero.js
const IntegrationLogos = ({className = ''}) => {
	return (
		<section className={`self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[40px] lg:pb-[120px] box-border max-w-full shrink-0 text-center text-xl font-plus-jakarta-sans ${className}`}>
			<div className="flex-1 flex flex-col items-center justify-start gap-[24px] max-w-full">
				<b className="self-stretch relative leading-[120%] text-transparent !bg-clip-text [background:linear-gradient(98.81deg,_#825bf8,_#5f44f6_44.79%,_#5caff9_96.35%)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-base mq450:leading-[19px]">
				Integrated with
				</b>
				<div className="self-stretch flex flex-col items-start justify-start max-w-full flex-wrap lg:flex-nowrap">
					<div className="flex flex-row items-center lg:justify-between py-0  w-full opacity-[0.4] gap-[0.7rem]  mq800:pl-[35px] mq800:pr-[35px] mq800:box-border mq1350:pl-[70px] mq1350:pr-[70px] mq1350:box-border">
						<a target="_blank" class="w-auto h-auto lg:h-full " href="http://entrepreneur.com">
							Optimizely
						</a>
						<a target="_blank" class="w-auto h-auto lg:h-full " href="http://bloomberg.com">
							Userinterviews
						</a>
						<a target="_blank" class="w-auto h-auto lg:h-full " href="http://forbes.com">
							Amplitude
						</a>
						<a target="_blank" class="w-auto h-auto lg:h-full " href="http://businessinsider.com">
							Meta Business Suite
						</a>
						<a target="_blank" class="w-auto h-auto lg:h-full " href="http://finance.yahoo.com">
						Mixpanel
						</a>
						<a>Qualtrics</a>
					</div>
				</div>

				{/* <div className={Style.marquee}>
					<div className={Style.marqueeContent}>
						<a target="_blank" className={`${Style.newslink} ${Style.marqueeItem}`} href="http://entrepreneur.com">
							<img className={Style.newsimage} src="/news/entrepreneur.webp" alt="Derisky Featured: Entrepreneur Magazine Logo white " />
						</a>
						<a target="_blank" className={`${Style.newslink} ${Style.marqueeItem}`} href="http://bloomberg.com">
							<img className={Style.newsimage} src="/news/bloomberg.png" alt="Derisky Featured: Bloomberg Magazine Logo white " />
						</a>
						<a target="_blank" className={`${Style.newslink} ${Style.marqueeItem}`} href="http://forbes.com">
							<img className={Style.newsimage} src="/news/forbes.webp" alt="Derisky Featured: Forbes Magazine Logo white " />
						</a>
						<a target="_blank" className={`${Style.newslink} ${Style.marqueeItem}`} href="http://businessinsider.com">
							<img className={Style.newsimage} src="/news/businessinsider.webp" alt="Derisky Featured: Business Insider Magazine Logo white " />
						</a>
						<a target="_blank" className={`${Style.newslink} ${Style.marqueeItem}`} href="http://finance.yahoo.com">
							<img className={Style.newsimage} src="/news/yahoo-finance_white.png" alt="Derisky Featured: Yahoo Finance Magazine Logo white " />
						</a>
					</div>
				</div> */}
			</div>
		</section>
	)
}

IntegrationLogos.propTypes = {
	className: PropTypes.string
}

export default IntegrationLogos
