/***
*
*   HERO
*   Hero section on landing page
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import {Animate, Content, Button, ClassHelper, Badge, Grid, Icon} from 'components/lib';
import Style from './hero.tailwind.js';
import CountDown from 'count-down-react';
import React from "react";

export function Hero(props) {
    const today = new Date();
    const lastDayOfMonth = today.getDay() > 15 ?
        new Date(today.getFullYear(), today.getMonth(), 15):
        new Date(today.getFullYear(), today.getMonth()+1, 1);

    function addZeroWhenOneDigit (number) {
        let formattedNumber = (number < 10 ? '0' : '') + number;
        return formattedNumber;
    }

    const heroStyle = ClassHelper(Style, {
    hero: true,
    className: props.className,
    });

const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return "test";
        } else {
            // Render a Countdown

            return (<div className="flex-1 justify-end">

                    <div className='text-3xl flex justify-end custom-line-through'>799$</div>
                    <div className='flex justify-end mt-1'>Enrollment free until {lastDayOfMonth.toLocaleDateString("us-en")}</div>

                    <ul className="flex justify-end">

                        <li className="shadow-xl mr-1 p-2 text-sm text-center">
                            <div className="text-3xl align-middle font-bold" id="days">{addZeroWhenOneDigit(days)}</div>days
                        </li>
                        <li className="shadow-xl mr-1 p-2 text-sm text-center">
                            <div className="text-3xl font-bold" id="hours">{addZeroWhenOneDigit(hours)}</div>hours
                        </li>
                        <li className="shadow-xl mr-1 p-2 text-sm text-center">
                            <div className="text-3xl font-bold" id="minutes">{addZeroWhenOneDigit(minutes)}</div>mins
                        </li>
                        <li className="shadow-xl mr-1 p-2 text-sm text-center">
                            <div className="text-3xl font-bold" id="seconds">{addZeroWhenOneDigit(seconds)}</div>secs
                        </li>
                    </ul>
                    <div className='float-right text-sm mt-2 w-auto inline-block p-1 bg-derisky-rose uppercase text-brand-400'>Only {addZeroWhenOneDigit(days)*3} seats left</div>             </div>

        );
        }
    };


    return (
    <section className={heroStyle}>
      <Content>
            <Animate type='pop'>
              <section className={Style.blurb}>
                <h1 className={Style.title}>
                  {props.title}</h1>

            <div className='flex'>
                <h2 className={Style.tagline}>
                    {props.tagline + "   "} {props.badge && <Badge className="uppercase bg-derisky-rose my-3 ml-5 shadow px-4 text-brand-400" text={props.badge} />}
                </h2>
            </div>


                  {
                      props.tags && <div>
                          {props.tags.map(tag => {
                              return (<Badge color={'green'} text={tag} />)
                          })}
                      </div>
                  }

                  <div className='flex text-justify gap-14 mb-4'>
                      <div className='flex-1  uppercase border-t-4 border-derisky-rosehover shadow-lg px-4 hover:shadow-md'>
                          <h4 className='my-3 flex gap-5 '><Icon image='fast-forward' color='white' />5x faster time-to-market</h4>
                      </div>
                      <div className='flex-1  uppercase border-t-4 border-derisky-rosehover shadow-lg px-4 hover:shadow-md'>
                          <h4 className='my-3 flex gap-5'><Icon image='rotate-ccw' color='white' />15x ROI over 3 years</h4>
                      </div>
                      <div className='flex-1  uppercase border-t-4 border-derisky-rosehover shadow-lg px-4 hover:shadow-md'>
                          <h4 className='my-3 flex gap-5'><Icon image='dollar-sign'/>Immediate cost savings</h4>
                      </div>
                  </div>
                  {props.countdown ?
                      <div className="flex justify-between items-center gap-2 flex-wrap">
                          <Button goto='/signup' text='Enroll now.' color={"derisky-rose"} big className={Style.button } />
                          <CountDown
                              date={lastDayOfMonth}
                              renderer={renderer}
                          />
                      </div> :
                      <Button goto='/signup' text='Derisk now.' color={"derisky-rose"} big className={Style.button} />

                  }
                  { props.showLaurens ? <div className="mt-8 flex justify-start self-center align-middle">
                    <img src="/Add/1710842465321.jpeg" alt="Laurens Lang Maker, Founder" className="w-10 my-3 shadow-xl rounded-full " />
                    <span className='self-center ml-4'>Made by <a href="http://linkedin.com/in/laurenslang" className='self-center' target="_blank">Laurens Lang M.Sc, MBA</a></span>

                  </div> : null }
              </section>


              <h4 className='mb-[-1] text-center'>KNOWN FROM MEDIA</h4>
              </Animate></Content>

        <div className={Style.marquee}>
            <div className={Style.marqueeContent}>
                <a target='_blank' className={`${Style.newslink} ${Style.marqueeItem}`} href='http://entrepreneur.com'>
                    <img className={Style.newsimage} src='/news/entrepreneur.webp' alt='Derisky Featured: Entrepreneur Magazine Logo white '/>
                </a>
                <a target='_blank' className={`${Style.newslink} ${Style.marqueeItem}`} href='http://bloomberg.com'>
                    <img className={Style.newsimage} src='/news/bloomberg.png' alt='Derisky Featured: Bloomberg Magazine Logo white '/>
                </a>
                <a target='_blank' className={`${Style.newslink} ${Style.marqueeItem}`} href='http://forbes.com'>
                    <img className={Style.newsimage} src='/news/forbes.webp' alt='Derisky Featured: Forbes Magazine Logo white '/>
                </a>
                <a target='_blank' className={`${Style.newslink} ${Style.marqueeItem}`} href='http://businessinsider.com'>
                    <img className={Style.newsimage} src='/news/businessinsider.webp' alt='Derisky Featured: Business Insider Magazine Logo white '/>
                </a>
                <a target='_blank' className={`${Style.newslink} ${Style.marqueeItem}`} href='http://finance.yahoo.com'>
                    <img className={Style.newsimage} src='/news/yahoo-finance_white.png' alt='Derisky Featured: Yahoo Finance Magazine Logo white '/>
                </a>
            </div>
        </div>



    </section>
  )
}