import React, { useState } from 'react';
import {Form} from "../../../components/lib";


// Card component that takes a title, form field, and an update handler
const CardForm = ({ product_id, field, label, integration }) => {
    const [isUpdated, setIsUpdated] = useState(false);
    const [value, setValue] = useState('');

    const handleInputChange = (e) => {
        setValue(e.target.value);
        setIsUpdated(true); // Change to true to toggle the icon to green
    };

    return (
        <div className="card bg-white shadow-md rounded-lg p-4 border border-gray-200" >
            {/* Card Header */}
            <div className="card-header flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">{label}</h2>
                <h4>{integration && integration[0] && integration[0][field]}</h4>
            </div>
            <div className="flex items-center mb-2">
                <div className={`w-3 h-3 rounded-full mr-2 ${
                    integration && 
                    integration[0] && 
                    integration[0][`is${field[0].toUpperCase() + field.slice(1)}`] 
                    ? 'bg-green-500' 
                    : 'bg-red-500'
                }`} />
                <span className="text-sm text-gray-600">
                    {integration && 
                     integration[0] && 
                     integration[0][`is${field[0].toUpperCase() + field.slice(1)}`] 
                     ? 'Connected' 
                     : 'Not Connected'}
                </span>
            </div>
            
            {label  && <Form
                inputs={{
                    [field]: {  // Wrap field in square brackets to dynamically assign the key
                        showLabel: false,
                        type: 'text',
                        required: true,
                        defaultValue: integration && integration[0] && integration[0][field],
                        placeholder: integration && integration[0] && integration[0][field],
                        errorMessage: 'Please enter the API Key'
                    },
                }}
                url={`/api/integration/${product_id}`}
                method='PUT'
                buttonText='Save'
                callback={(res) => alert('Updated successfully')}
                className=" w-full border border-gray-300 rounded px-3 py-3 focus:outline-none focus:border-blue-500"
            />}

        </div>
    );
};

// Main component that renders each form in a grid layout
const IntegrationForms = ({product_id, integration}) => {
    const formFields = [
        { field: 'optimizely', label: 'Optimizely' },
        /*{ field: 'metabusinessmanager', label: 'Meta Business Manager' },
        { field: 'amplitude', label: 'Amplitude' },
        { field: 'googleanalytics', label: 'Google Analytics' },
        { field: 'mixpanel', label: 'Mixpanel' },
        { field: 'unbounce', label: 'Unbounce' },
        { field: 'hotjar', label: 'Hotjar' },
        { field: 'segment', label: 'Segment' },
        { field: 'mailchimp', label: 'Mailchimp' },
        { field: 'tealium', label: 'Tealium' },
        { field: 'rudderstack', label: 'Rudderstack' },
        { field: 'appcues', label: 'Appcues' },
        { field: 'kissmetrics', label: 'Kissmetrics' },
        { field: 'webflow', label: 'Webflow' },
        { field: 'leadpages', label: 'Leadpages' },*/
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-1 gap-6 p-6">
            {formFields.map((field) => (
                <CardForm integration={integration} key={field.field} field={field.field} label={field.label} product_id={product_id} />
            ))}
        </div>
    );
};

export default IntegrationForms;
